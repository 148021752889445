.locations {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 25px;

  @include sm {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
  }

  @include lg {
    grid-template-columns: repeat(3, 1fr);
  }

  &__image {
    margin-bottom: 20px;
  }

  &__location {
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
  }

  &__name {
    display: flex;
    align-items: end;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 0.9rem;
    height: 52px;
  }

  &__address {
    text-align: center;

    &:last-of-type {
      margin-bottom: 30px;
    }
  }

  &__address-link {
    color: $black;
    text-decoration: none;
    text-align: center;

    .locations__address {
      display: block;
    }
  }

  &__link {
    color: $black;
    text-decoration: none;
    display: block;
    text-align: center;
  }

  &__mail {
    color: $orange;
    text-decoration: none;
    margin-top: 25px;
    text-align: center;
  }
}
