.text-block {
  font-family: $font-serif;
  font-style: normal;
  margin: 0;

  &--small {
    font-size: $font-size-xs;
    line-height: $font-size-s;
  }

  &--medium {
    font-size: $font-size-base;
    line-height: 26px;
  }

  &--large {
    font-size: $font-size-base;
    line-height: 26px;

    @include sm {
      font-size: $font-size-m;
      line-height: 30px;
    }
  }

  &--orange {
    color: $orange;
    text-decoration: none;
  }

  &--centered {
    text-align: center;
  }
}
