$black: #000000;
$soft-black: #1d1d1d;
$white: #ffffff;
$off-white: #fcfafa;
$orange: #ff6932;
$grey-light: #f8f6f6;
$grey-light-2: #efeded;
$grey-medium: #9d9d9d;
$grey-dark: #727272;
$mint-light: #ecf3f2;
