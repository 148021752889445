.carousel-gallery {
  width: 100%;
  /* stylelint-disable-next-line */
  aspect-ratio: 16/9;
  max-height: calc(100vh - 80px);
  position: relative;

  .headline {
    @include lg {
      font-size: $font-size-xl;
      line-height: 52px;
    }
  }

  &__headline {
    text-align: center;
    margin-bottom: 0;
  }

  &__headline-wrapper {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    box-sizing: border-box;
    justify-content: center;
    flex-direction: column;

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }

    &--box {
      background-color: rgba(255, 255, 255, 0.8);
      padding: 30px 50px;
      color: black;
      width: 500px;

      .carousel-gallery {
        &__headline {
          font-size: $font-size-l;
          line-height: 46px;
        }
      }
    }
  }

  .swiper-slide {
    img {
      position: absolute;
      min-height: 100%;
      width: 100%;
      object-fit: cover;
      transform-origin: bottom left;
      transform: scale(1.1);
      animation: none;
      animation-fill-mode: both;
      animation-iteration-count: infinite;

      @-webkit-keyframes move {
        0% {
          transform: scale(1);
        }

        100% {
          transform: scale(1.1);
        }
      }
    }
  }

  .swiper-wrapper {
    @include md {
      .swiper-slide {
        &-active {
          img {
            animation: move 7.2s ease-out;
          }

          .hero-block__content-animation-wrapper.active {
            animation: move-text 7.2s ease-out;
          }
        }
      }

      &.first {
        .swiper-slide {
          &-active {
            img {
              animation: move 5s ease-out;
            }

            .hero-block__content-animation-wrapper.active {
              animation: move-text 3s ease-out;
            }
          }
        }
      }
    }
  }

  .swiper-pagination {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    z-index: 20;
    max-width: 80%;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .swiper-pagination-bullet {
    position: relative;
    height: 2px;
    padding: 20px 0;
    opacity: 1;
    background: none;
    width: 100%;
    max-width: 117px;
    min-width: 37px;

    @include sm {
      padding: 30px 0;
    }

    &::after {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      width: 100%;
      padding-top: 2px;
      border-radius: 2px;
      background-color: $white;
      transition: padding-top 0.2s;
      transition-timing-function: linear;

      @include sm {
        padding-top: 3px;
      }
    }

    &::before {
      visibility: hidden;
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      width: 0;
      padding-top: 2px;
      border-radius: 2px;
      background-color: $orange;
      z-index: 10;
      transition: width 5s, padding-top 0.2s;
      transition-timing-function: linear;

      @include sm {
        padding-top: 3px;
      }
    }

    &-active {
      &::before {
        visibility: visible;
        width: 100%;
      }
    }
  }
}

.content--filter-active,
.content--subNav-active {
  .carousel-gallery {
    @include md {
      max-height: calc(100vh - 130px);
    }
  }
}
