.navigation-link-states {
  @include link-reset;

  color: $black;
  position: relative;
  padding: 5px 0 7px 0;
  margin-right: auto;
  overflow: hidden;
  font-family: $font-sans;
  text-transform: uppercase;
  font-size: $font-size-xs;

  @include md {
    font-weight: normal;

    &:hover::before,
    &:focus::before {
      opacity: 1;
      transition: $transition-navigation;
      width: 100%;
    }

    &:hover,
    &:focus {
      outline: 0;
      color: $orange;
      transition: $transition-button;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $orange;
    height: 2px;
    width: 0;
    opacity: 0;
  }

  &:focus::before {
    opacity: 1;
    transition: $transition-navigation;
    width: 100%;
  }

  &:focus {
    outline: 0;
    color: $orange;
    transition: $transition-button;
  }

  &.active {
    color: $orange;
    position: relative;
    transition: $transition-navigation;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $orange;
      height: 2px;
      width: 100%;
      opacity: 1;
    }
  }

  &.leaving-border {
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: $orange;
      height: 2px;
      width: 100%;
      animation: border-leave 0.6s;

      @include md {
        content: "";
      }
    }
  }
}

@keyframes border-leave {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}
