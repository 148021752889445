// Ein BR soll Grundsätzlich erst ab MD angezeigt werden.
br {
  &.md-max {
    display: inline;

    @include md {
      display: none;
    }
  }

  &.md-min {
    display: none;

    @include md {
      display: inline;
    }
  }
}
