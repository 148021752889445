$animation-time: 0.4s;

.header {
  transform: translate3d(0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  &.on-scroll {
    @include md {
      .header {
        &-main {
          padding: 0;

          &__logo {
            transform: scale(1);
          }
        }
      }
    }
  }

  &--sub-active {
    @include md {
      margin-bottom: 45px;
    }
  }

  &-main {
    will-change: padding;
    height: 80px;
    background-color: white;
    border-bottom: solid 1px $grey-light-2;
    transition: padding $animation-time;
    box-sizing: content-box;

    &__container {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include lg {
        justify-content: space-between;
      }
    }

    &__logo-wrapper {
      display: flex;
    }

    &__logo {
      transition: transform $animation-time;
      //transform: scale(1.2);
      height: 40px;
    }
  }

  &-navigation {
    background-color: #fcfafa;
  }
}
