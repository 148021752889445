.newest-references {
  & > *:not(:first-child) {
    margin-top: 30px;

    @include sm {
      margin-top: 50px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

    @include sm {
      flex-direction: row;
    }
  }

  &__button {
    margin-top: 70px;
  }

  &__tile-wrapper {
    margin-bottom: 30px;

    @include sm {
      width: calc(50% - 20px);
      margin-bottom: 0;

      &:nth-child(even) {
        margin-top: 50px;
        margin-left: 20px;
      }

      &:nth-child(odd) {
        margin-right: 20px;
      }
    }

    @include md {
      width: calc(50% - 30px);

      &:nth-child(even) {
        margin-left: 30px;
        margin-top: 65px;
      }

      &:nth-child(odd) {
        margin-right: 30px;
      }
    }

    @include lg {
      width: calc(50% - 50px);

      &:nth-child(even) {
        margin-left: 50px;
        margin-top: 95px;
      }

      &:nth-child(odd) {
        margin-right: 50px;
      }
    }

    &:not(:nth-child(-n+2)) {
      display: none;

      @include sm {
        display: block;
      }
    }

    &:nth-of-type(2) {
      margin-bottom: 0;
    }
  }

  .headline {
    text-align: center;
  }

  .tile {
    @include sm {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
    }

    &__text {
      @include sm {
        font-size: $font-size-base;
        line-height: $font-size-m;
      }

      @include md {
        font-size: $font-size-m;
        line-height: $font-size-l;
      }
    }
  }

  &--milestone {
    @include md {
      margin-top: 0 !important;
    }

    .shifted-wrapper__number {
      margin-bottom: -7vw;

      @include sm {
        margin-bottom: -4vw;
      }
    }

    .shifted-wrapper__tile-wrapper {
      margin-right: 0;
    }

    .tile {
      @include sm {
        width: 90%;
        margin-left: 80px;
        margin-right: 0;

        @include sm {
          margin-left: 4vw;
        }

        @include xl {
          margin-left: 6vw;
        }
      }
    }
  }

  .teaser {
    margin: 0;
  }
}
