// Button Element zurücksetzen.
//
// button.example {
//   @include button-reset;
// }
@mixin button-reset {
  background: none;
  border: none;
  padding: 0;
  -webkit-appearance: none;
}
