.footer {
  background-color: $black;
  padding: 50px 0;

  &__copy-social-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include md {
      flex-direction: column-reverse;
      align-items: end;
    }

    @include lg {
      flex-direction: row;
      align-items: center;
    }
  }

  &__social-container {
    display: flex;
    column-gap: 12px;
  }

  &__socialLink {
    width: 24px;
    height: 24px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include sm {
      flex-direction: row;
    }
  }

  &__nav-list-wrapper {
    display: flex;
  }

  &__nav-list {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;

    @include md {
      flex-direction: row;
    }
  }

  &__nav-item {
    margin-bottom: 10px;

    @include md {
      margin-bottom: 0;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  &__copyright {
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: #808080;

    @include sm {
      margin-right: 40px;
    }

    @include md {
      margin-right: 0;
    }

    @include lg {
      margin-right: 40px;
    }
  }
}
