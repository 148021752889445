//$sm: 576px;
//$md: 768px;
//$lg: 992px;
//$xl: 1200px;
//$xxl: 1400px;

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;

  @include md {
    padding: 0 70px;
  }

  @include lg {
    padding: 0 70px;
  }

  @include xxl {
    padding: 0 20px;
    max-width: 1200px;
  }

  &--big {
    padding: 0 20px;

    @include lg {
      max-width: 1400px;
    }
  }

  &--small {
    @include lg {
      max-width: 800px;
    }
  }

  &--full {
    max-width: none;
    padding: 0;
  }
}
