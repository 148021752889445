.usp {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  @include xl {
    height: 375px;

    &:hover {
      .usp__slide-up-container {
        max-height: 100%;
      }

      .usp__text {
        max-height: 230px;
        overflow-y: auto;
      }
    }
  }

  &__image-wrapper {
    height: 260px;
    overflow: hidden;
    z-index: -1;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  &__headline-wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;

    @include lg {
      min-height: 55px;
      height: 60px;
    }

    @media (hover: hover) {
      min-height: 55px;
    }

    .headline {
      min-height: unset;
    }
  }

  &__headline {
    text-align: left;
    margin: 0;
    font-size: 1.4rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  &__slide-up-container {
    position: relative;
    background-color: rgba($grey-light, 0.8);
    padding: 30px 25px 30px;
    width: 100%;
    transition: max-height 1s cubic-bezier(0.1, 0.67, 0.47, 0.97);
    bottom: 0;
    flex-grow: 1;

    @include xl {
      position: absolute;
      padding: 30px 25px;
      max-height: 115px;
    }
  }

  &__text {
    & > *:not(:first-child) {
      margin-top: 8px;
    }

    p {
      margin: 0;
    }

    ul {
      @include list-reset;

      li {
        text-indent: -1rem;
        padding-left: 1rem;

        &::before {
          display: inline-block;
          position: relative;
          content: "";
          border-radius: 50%;
          height: 3px;
          width: 3px;
          top: -4px;
          margin-right: calc(1rem - 3px);
          background-color: $orange;
        }
      }
    }

    a {
      color: $orange;
      text-decoration: none;
    }
  }
}
