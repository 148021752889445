.slideshow {
  &__container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: start;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "content"
      "image"
      "button";

    @include md {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
        "content image"
        "button image";
    }
  }

  &__headline {
    text-align: center;
    margin-bottom: 25px;

    @include md {
      margin-bottom: 40px;
    }
  }

  &__image {
    grid-area: image;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__button {
    grid-area: button;
    padding: 0 50px;
    display: flex;
    justify-content: center;
    margin-top: 30px;

    @include md {
      grid-area: button;
    }
  }

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    grid-area: content;

    @include md {
      padding: 0 50px;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 30px;

    @include md {
      margin-bottom: 0;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }
}

.slideshow-slider {
  position: relative;
  width: 100%;
  margin: 0;

  @include md {
    padding-right: 60px;
  }

  &__button {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translate(0, -50%);
    z-index: 1000;

    @include md {
      right: 0;
    }
  }
}
