.table {
  width: 100%;
  table-layout: fixed;

  &__row {
    vertical-align: initial;
    display: flex;
    flex-direction: column;
    padding: 20px 0 10px;
    border-bottom: 1px solid $grey-light-2;
    justify-content: flex-start;
    text-align: left;
    white-space: normal;

    @include sm {
      flex-direction: row;
      padding: 30px 0;
      align-items: baseline;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  &__head {
    margin-bottom: 10px;

    @include sm {
      width: 20%;
      flex-shrink: 0;
      margin-bottom: 0;
      margin-right: 30px;
    }
  }

  &__cell {
    width: 100%;
    white-space: normal;

    @include sm {
      width: calc(80% - 30px);
      margin-right: 20px;
    }
  }

  &__content {
    font-style: normal;
    vertical-align: baseline;
    white-space: normal;

    a {
      white-space: nowrap;
      color: $orange;
      text-decoration: none;
    }

    p:first-child {
      margin-top: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__text {
    display: block;
    width: 100%;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include sm {
      max-width: 500px;
    }
  }

  &__link {
    white-space: nowrap;
    color: $orange;
    text-decoration: none;
  }
}
