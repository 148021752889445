.accordion {
  position: relative;

  &__number {
    font-family: $font-sans;
    font-size: $font-size-xs;
    position: relative;
    color: #c8cfd8;
    margin-left: 5px;

    &::after {
      content: "";
      position: absolute;
      right: -30px;
      top: -2px;
      width: 10px;
      height: 10px;
      background-color: transparent;
      border-bottom: 1px solid $black;
      border-right: 1px solid $black;
      transform: rotate(45deg);
      transform-origin: center;
      transition: all 0.35s;
    }
  }

  &__button {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding: 23px 0;
    line-height: $font-size-m;
    width: 100%;
    color: $black;
    transition: color 0.35s;
    text-align: left;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: $grey-light-2;
    }

    &:focus,
    &:active {
      outline: none;
    }

    &.open {
      color: $orange;

      & > .accordion__number {
        &::after {
          border-color: $orange;
          transform: rotate(225deg);
          top: 6px;
        }
      }
    }
  }

  &__list {
    @include list-reset;

    transition: height 0.35s;
    overflow-y: hidden;
  }

  &__department {
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__link {
    @include link-reset;

    display: flex;
    flex-direction: column;

    @include sm {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      max-width: 860px;
      margin-left: 100px;
    }

    @include lg {
      margin-left: 200px;
      justify-content: flex-start;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  &__list-item {
    position: relative;
    padding: 23px 0;
    width: 100%;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: $grey-light-2;
    }
  }

  &__title {
    color: $black;
    order: 2;

    @include sm {
      width: 55%;
      order: 1;
    }
  }

  &__location {
    order: 1;
    margin-bottom: 10px;

    @include sm {
      margin-bottom: 0;
      width: 20%;
      order: 2;
    }
  }
}
