.blockquote {
  margin: 0;

  &__quote {
    text-align: center;
    font-size: $font-size-m;
    font-style: italic;
    line-height: 26px;
    letter-spacing: 0.05em;
    padding: 0;
    margin: 0 0 16px;
  }

  &__author {
    text-align: center;
    font-size: $font-size-xs;
  }
}
