.dataAndFacts {
  color: $white;

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 10px;
    row-gap: 24px;
  }

  &__data-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__unit {
    text-wrap: nowrap;
  }

  &__counter-wrapper {
    position: relative;
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  &__counter {
    position: absolute;
    top: 0;

    &--hidden {
      visibility: hidden;
    }
  }

  &__fact-wrapper {
    text-align: center;
    margin: 0 auto;
    min-width: 100%;

    @include md {
      min-width: calc(50% - 40px);
      padding: 0 20px;
    }

    @include lg {
      min-width: calc(25% - 40px);
    }
  }

  & + .locations {
    margin-top: $flow-mobile;

    @include md {
      margin-top: $flow;
    }
  }
}
