.text {
  font-family: $font-sans;
  margin: 0;

  &--footer {
    @include link-reset;

    text-transform: uppercase;
    font-size: $font-size-xs;
    font-weight: 500;
    color: $white;
  }

  &--bold-uppercase {
    @include link-reset;

    text-transform: uppercase;
    font-size: $font-size-xs;
    font-weight: 500;
    color: $black;
  }

  &--data {
    font-size: $font-size-xl;
    font-weight: bold;
    color: $white;
  }

  &--download {
    font-weight: bold;
    color: $black;
    font-size: $font-size-m;
    letter-spacing: 1px;
    line-height: 29px;
  }

  &--uppercase {
    @include link-reset;

    text-transform: uppercase;
    font-size: $font-size-xs;
    font-weight: 400;
    color: $black;
  }

  &--outlined {
    line-height: 1;
    font-size: 17vw;
    font-weight: bold;
    color: $off-white;
    text-shadow:
      -1px -1px 0 $grey-medium,
      1px -1px 0 $grey-medium,
      -1px 1px 0 $grey-medium,
      1px 1px 0 $grey-medium;

    @include sm {
      font-size: 13vw;
    }

    @include md {
      font-size: 11vw;
    }

    @include lg {
      font-size: 9vw;
    }

    @include xl {
      font-size: $font-size-xxl;
    }
  }
}
