.tile {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: auto;
  align-self: flex-start;
  grid-column: span 2;

  @include md {
    height: 100%;
  }

  &__image-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;

    @include lg {
      margin-bottom: 30px;
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
  }

  &__content {
    @include link-reset;

    color: black;
    text-align: center;
    max-width: 444px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &__overline {
    font-family: $font-sans;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__text {
    line-height: 30px;
    padding: 0 10px;
  }

  &__link {
    @include link-reset;

    color: black;

    &:hover {
      color: $orange;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}
