.is-hidden {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  pointer-events: none;
}

.is-visible {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.stop-transition {
  * {
    transition: none !important;
  }
}

.is-hidden-md {
  @include md {
    display: none;
  }
}
