.row-of-three {
  @include list-reset;

  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;

  @include sm {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
  }

  @media (min-width: 576px) and (max-width: 1199px) {
    & > * {
      grid-column: span 2;
    }

    /* Dealing with single orphan */
    & > *:last-child:nth-child(odd) {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);

    & > * {
      grid-column: span 2;
    }

    /* Dealing with 2 orphan items */
    & > *:last-child:nth-child(3n - 1) {
      grid-column-end: -2;
    }

    & > *:nth-last-child(2):nth-child(3n + 1) {
      grid-column-end: 4;
    }

    /* Dealing with single orphan */
    & > *:last-child:nth-child(3n - 2) {
      grid-column-end: 5;
    }
  }
}
