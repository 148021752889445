.row-of-two {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 50px;

  @include sm {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 50px;
    grid-column-gap: 30px;
  }

  @include md {
    grid-row-gap: 80px;
  }

  & > * {
    justify-self: center;
  }

  & > *:nth-of-type(even) {
    @include md {
      justify-self: end;
    }
  }

  & > *:nth-of-type(odd) {
    @include md {
      justify-self: start;
    }
  }
}
