.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  word-break: normal;

  &:focus {
    clip: auto !important;
    background-color: var(--color-primary);
    -webkit-clip-path: none;
    clip-path: none;
    color: var(--color-primary-invert);
    display: block;
    font-size: 0.9em;
    font-weight: 700;
    height: auto;
    right: 0.5rem;
    line-height: normal;
    padding: 0.3em 0.75em;
    text-decoration: none;
    top: 0.5rem;
    width: auto;
    z-index: 100000;
  }
}

.skip-link {
  left: -9999rem;
  top: 2.5rem;
  z-index: 999999999;
  text-decoration: underline;

  &:focus {
    display: block;
    left: 6px;
    top: 7px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    line-height: normal;
    padding: 15px 23px 14px;
    z-index: 100000;
    right: auto;
  }
}
