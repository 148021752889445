.side-navigation {
  display: none;

  @include lg {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 15px;
    width: 170px;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 30px;

      @include md {
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
  }
}
