.icon-button {
  @include link-reset;

  display: inline-block;
  text-transform: uppercase;
  line-height: $font-size-s;
  color: $black;
  height: 1em;
  font-family: $font-sans;
  font-weight: normal;

  &::before {
    content: "";
    display: inline-block;
    position: relative;
    width: 1em;
    height: 100%;
    background: url("../../images/svgs/share.svg") no-repeat 50% 50%; /* stylelint-disable-line declaration-no-important */
    background-size: contain;
    margin-right: 10px;
  }

  &--share {
    &::before {
      background: url("../../images/svgs/icon-share.svg") no-repeat 50% 50%; /* stylelint-disable-line declaration-no-important */
    }
  }

  &--pdf {
    &::before {
      background: url("../../images/svgs/icon-pdf.svg") no-repeat 50% 50%; /* stylelint-disable-line declaration-no-important */
    }
  }
}
