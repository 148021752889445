.filter {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  height: 45px;
  z-index: 99;
  overflow-y: hidden;
  overflow-x: auto;
  background-color: $off-white;
  transition: transform 0.4s;

  @include md {
    top: 80px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;
    margin: 0 50px;
    width: auto;
    min-width: min-content;

    @include md {
      margin: 0 auto;
      padding: 0 50px;
      justify-content: center;
    }
  }

  &__item {
    white-space: nowrap;

    &:last-child {
      margin-right: 50px;

      @include md {
        margin-right: 0;
      }
    }

    &:not(:last-child) {
      margin-right: 20px;

      @include md {
        margin-right: 45px;
      }
    }
  }
}
