.article-image-grid {
  position: relative;

  @include sm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    row-gap: 50px;
    grid-auto-flow: row;
  }

  @include md {
    column-gap: 100px;
    row-gap: 100px;
  }

  @include xl {
    column-gap: 155px;
    row-gap: 135px;
  }

  &--green {
    &::after {
      content: "";
      position: absolute;
      background-color: $mint-light;
      top: 0;
      left: -100%;
      right: -100%;
      height: 50%;
      z-index: -1;

      @include sm {
        height: 70%;
      }

      @include md {
        column-gap: 80px;
        row-gap: 80px;
      }
    }
  }

  & > * + * {
    margin-top: 50px;

    @include sm {
      margin-top: 0;
    }
  }

  .article-image-grid__image {
    &--normal {
      min-height: 138px;

      img {
        object-fit: cover;
        width: 100%;
      }
    }

    &--high {
      grid-row: span 2;
      min-height: 276px;
      object-fit: cover;

      @include sm {
        & + .article-image-grid__image--normal + .article-image-grid__image--normal {
          align-self: end;
        }
      }

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;

        @include sm {
          height: auto;
        }
      }
    }
  }
}
