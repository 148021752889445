.body-text {
  &--grey {
    background-color: $grey-light;
    padding: 30px 0;

    @include md {
      padding: 50px 0;
    }
  }

  h1 {
    @extend .headline;
    @extend .headline--level-1;

    line-height: 29px;
    text-align: left;
    margin: 0;

    @include sm {
      line-height: $font-size-xl;

      &:not(:last-child) {
        margin: 0 0 50px 0;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    @extend .headline;
    @extend .headline--level-2;

    margin: 0;
    text-align: left;

    @include sm {
      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }
  }

  h3 {
    @extend .text;
    @extend .text--uppercase;

    margin-bottom: 50px;
    font-weight: 700;
    display: block;
  }

  h4 {
    @extend .text;
    @extend .text--uppercase;

    margin-bottom: 50px;
  }

  p {
    @extend .text-block;

    font-size: $font-size-base;
    line-height: 26px;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  ul,
  ol {
    margin-top: 25px;

    li {
      & > *:not(:first-child) {
        margin-top: 5px;
      }
    }
  }

  ul {
    @include list-reset;

    margin-top: 25px;

    li {
      text-indent: -1rem;
      padding-left: 1rem;
      line-height: 26px;

      &::before {
        display: inline-block;
        position: relative;
        content: "";
        border-radius: 50%;
        height: 3px;
        width: 3px;
        top: -4px;
        margin-right: calc(1rem - 3px);
        background-color: $orange;
      }
    }
  }

  ol {
    li {
      line-height: 26px;
      margin-left: -23px;

      &::marker {
        color: $orange;
        font-size: 14px;
      }
    }
  }

  strong {
    font-weight: bold;
  }

  a {
    color: $orange;
    text-decoration: none;
  }

  .content-wrapper h1:first-child {
    margin-top: 50px;

    @include md {
      margin-top: 80px;
    }
  }
}
