// Dieses Mixin generiert Media Queries. Dazu kann dem Mixin ein String oder eine Map übergeben werden.
// Wird nur der Breakpoint übergeben wird eine min-width Query generiert.

// Breakpoints nach Bootstrap Standard.
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

// Media Queries - mobile first apporach:
// @include sm {...} für 576px window width und mehr

// Small devices
@mixin sm {
  @media (min-width: #{$sm}) {
    @content;
  }
}

@mixin sm-max {
  @media (max-width: #{$sm + 1}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$md}) {
    @content;
  }
}

@mixin md-max {
  @media (max-width: #{$md + 1}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$lg}) {
    @content;
  }
}

@mixin lg-max {
  @media (max-width: #{$lg + 1}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$xl}) {
    @content;
  }
}

@mixin xl-max {
  @media (max-width: #{$lg + 1}) {
    @content;
  }
}

// Extra Extra large devices
@mixin xxl {
  @media (min-width: #{$xxl}) {
    @content;
  }
}

@mixin xxl-max {
  @media (max-width: #{$xl + 1}) {
    @content;
  }
}

// Custom devices - für jede andere Display Größe
@mixin rwd($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}
