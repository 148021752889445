.image {
  display: block;
  position: absolute;
  height: 100%;
  margin: 0;
  padding: 0;
  max-height: 100%;
  width: 100vw;
  top: 50%;
  left: 50%;
  object-fit: cover;
  transform: translate(-50%, -50%);
}
