.contact {
  position: relative;

  &-banner {
    &__headline {
      font-weight: bold;
      font-size: 0.9rem;
    }
  }

  &__image-wrapper {
    overflow: hidden;

    @include md {
      height: 325px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .banner-white {
    padding-top: 30px;
    padding-bottom: 45px;

    @include sm {
      padding-top: 50px;
      padding-bottom: 80px;
    }
  }

  & > *:last-child {
    @include sm {
      margin-bottom: 50px;
    }
  }
}
