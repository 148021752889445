.content {
  padding-top: 80px;

  @include md {
    padding-top: 80px;
  }

  &--subNav-active {
    padding-top: 80px;

    @include lg {
      padding-top: 130px;
    }
  }

  &--filter-active {
    padding-top: 125px;

    @include md {
      padding-top: 130px;
    }
  }
}
