/* stylelint-disable no-descending-specificity */
.tile-row {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 50px;
  grid-template-columns: repeat(2, 1fr);

  @include sm {
    grid-template-columns: repeat(4, 1fr);
  }

  &--two {
    @media (min-width: 576px) {
      grid-template-columns: repeat(4, 1fr);

      & > * {
        grid-column: span 2;
      }

      /* Dealing with single orphan */
      & > *:last-child:nth-child(odd) {
        grid-column-start: 2;
        grid-column-end: 4;
      }
    }
  }

  &--three {
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: 576px) and (max-width: 859px) {
      grid-template-columns: repeat(4, 1fr);

      & > * {
        grid-column: span 2;
      }

      /* Dealing with single orphan */
      & > *:last-child:nth-child(odd) {
        grid-column-start: 2;
        grid-column-end: 4;
      }
    }

    @media (min-width: 860px) {
      grid-template-columns: repeat(6, 1fr);

      & > * {
        grid-column: span 2;
      }

      /* Dealing with 2 orphan items */
      & > *:last-child:nth-child(3n - 1) {
        grid-column-end: -2;
      }

      & > *:nth-last-child(2):nth-child(3n + 1) {
        grid-column-end: 4;
      }

      /* Dealing with single orphan */
      & > *:last-child:nth-child(3n - 2) {
        grid-column-end: 5;
      }
    }
  }

  &--four {
    grid-template-columns: repeat(2, 1fr);

    @media (min-width: 576px) and (max-width: 859px) {
      grid-template-columns: repeat(4, 1fr);

      & > * {
        grid-column: span 2;
      }

      /* Dealing with single orphan */
      & > *:last-child:nth-child(odd) {
        grid-column-start: 2;
        grid-column-end: 4;
      }
    }

    @media (min-width: 860px) {
      grid-template-columns: repeat(8, 1fr);

      & > * {
        grid-column: span 2;
      }

      /* Dealing with 2 orphan items */
      & > *:last-child:nth-child(4n - 1) {
        grid-column-end: -3;
      }

      & > *:nth-last-child(2):nth-child(4n + 1) {
        grid-column-end: 5;
      }

      /* Dealing with single orphan */
      & > *:last-child:nth-child(4n - 3) {
        grid-column-end: 6;
      }
    }

    &-last-row-of-three {
      @media (min-width: 860px) {
        grid-template-columns: repeat(8, 1fr);

        & > * {
          grid-column: span 2;
        }

        /* Dealing with 2 orphan items */
        & > *:last-child:nth-child(4n - 1) {
          grid-column-end: -2;
        }

        & > *:nth-last-child(2) {
          grid-column-end: 6;
        }

        /* Dealing with single orphan */
        & > *:nth-last-child(3) {
          grid-column-end: 4;
        }
      }
    }
  }
}
/* stylelint-enable no-descending-specificity */
