.tile-cta {
  display: flex;
  flex-direction: column;
  padding: 0 $content-padding-mobile;
  margin-left: auto;
  margin-right: auto;

  @include md {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0;
  }

  &__button {
    margin-bottom: 80px;

    @include md {
      margin-bottom: 0;
    }
  }

  &__overline {
    margin-bottom: $element-vertical-spacing-mobile;
  }

  &__headline {
    margin-bottom: $element-vertical-spacing-mobile;
  }

  &__image-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    order: 1;
    width: 100%;
    height: 288px;
    margin-bottom: $element-vertical-spacing-mobile;

    @include sm {
      height: 350px;
    }

    @include md {
      order: 2;
      width: 469px;
      height: 400px;
      margin: 30px 30px 0;
    }

    @include lg {
      height: 540px;
    }
  }

  &__content-wrapper {
    order: 2;

    @include md {
      order: 1;
      width: 519px;
      margin-top: auto;
      margin-bottom: auto;
    }

    &--center {
      @include md {
        margin: auto;
      }
    }
  }

  &__content {
    text-align: center;
    align-self: flex-start;
    margin-left: auto;
    margin-right: auto;

    @include md {
      max-width: 520px;
      margin: auto $content-padding;
    }

    @include lg {
      margin-right: 0;
    }
  }

  &__text {
    margin: 0 auto;
    margin-bottom: 36px;

    @include md {
      margin-bottom: 50px;
    }
  }
}
