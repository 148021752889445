.tile-hero {
  &__wrapper {
    display: flex;
    flex-direction: column;

    @include md {
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
    }
  }

  &__image-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    text-align: center;
    padding-top: $element-vertical-spacing-mobile;

    @include sm {
      padding-top: 45px;
    }

    @include md {
      //height: 50vw;
      //max-height: 460px;
      width: 50%;
      height: auto;
      text-align: right;
      order: 0;
      padding-top: 0;
      //min-height: 434px;
    }

    .tile-image {
      position: absolute;
      top: 50%;
      left: 50%;
      object-fit: cover;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
    }

    .tile-hero--reverse & {
      @include md {
        order: 1;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &__button {
    width: 253px;

    @include md {
      margin-top: 50px !important;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;

    @include md {
      width: 50%;
      align-self: center;
      padding-top: 0;
      order: 1;
    }

    .tile-hero--reverse & {
      @include md {
        order: 0;
      }
    }
  }

  &__content {
    @include link-reset;

    text-align: center;
    align-self: flex-start;
    margin-left: auto;
    margin-right: auto;
    color: $black;
    width: calc(100% - 60px);

    @include md {
      padding: 30px 0;
    }

    @include lg {
      width: auto;
      padding: 20px 50px;
    }

    & > *:not(:first-child) {
      margin-top: $element-vertical-spacing-mobile;
    }

    //verhindert, dass telefonnummern auf mobile devices, als default links gestylt werden.
    //auch wenn es kein link gab.
    a {
      &[href^="tel:"] {
        color: $black;
        text-decoration: none;
      }
    }
  }

  &__text {
    display: block;

    @include sm {
      margin: 0 auto;
      overflow: hidden;
    }

    @include md {
      margin: 0 auto;
    }

    &--big {
      font-size: $font-size-m;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    & > *:not(:first-child) {
      margin-top: $element-vertical-spacing-mobile;
      margin-bottom: 0;
    }
  }

  &--full-width {
    max-width: $content-width-wide;
    margin: 0 50px;

    @include sm {
      padding-left: 0;
      padding-right: 0;
    }

    @include md {
      margin: 0 auto;
    }

    .tile-hero__content {
      align-self: center;
      width: auto;
      padding: 0;
      margin: 0;

      @include sm {
        margin-right: 30px;
        margin-left: 30px;
      }

      @include md {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    .tile-hero__text {
      display: block;

      @include sm {
        height: auto;
        max-width: none;
      }
    }

    .tile-hero__image-wrapper {
      width: auto;

      @include sm {
        height: 350px;
        max-height: 100%;
      }

      @include md {
        height: auto;
        max-height: unset;
        width: calc(50% + 90px);
        margin-left: -90px;
      }

      @include lg {
        height: 540px;
      }

      @include xl {
        height: 467px;
        max-height: 518px;
      }

      img {
        width: 100%;
      }
    }

    .title-hero__content-wrapper {
      @include md {
        width: 50%;
        padding-top: 67px;
      }
    }
  }

  .button {
    width: 100%;
  }

  &--black {
    .tile-hero {
      &__headline {
        color: white;
      }

      &__kicker {
        color: white;
      }

      &__text {
        color: white;
      }
    }
  }
}
