.slider-tiles {
  padding: 0 10px;

  @include sm {
    padding: 0 20px;
  }

  &__container {
    display: flex;
    flex-wrap: nowrap;
    position: relative;

    @include md {
      align-items: center;
    }
  }

  &.grey {
    max-width: unset;

    .slider-tiles__container {
      max-width: $content-width;
      margin: 0 auto;
    }
  }

  .swiper-wrapper {
    display: flex;
  }

  .swiper-slide {
    height: auto;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: block;
    flex-shrink: 0;
    transform: translateY(calc(286px / 2));

    @include sm {
      transform: translateY(calc(350px / 2));
    }

    @include md {
      transform: unset;
    }

    &.swiper-button-disabled {
      visibility: hidden;
    }
  }

  .swiper-button-prev {
    left: 0;
    margin-right: 10px;

    @include sm {
      margin-right: 15px;
    }
  }

  .swiper-button-next {
    right: 0;
    margin-left: 10px;

    @include sm {
      margin-left: 15px;
    }
  }

  .tile-hero {
    height: 100%;

    &__wrapper {
      padding: 0;
      height: 100%;
    }

    &__content {
      padding: 0 5px;
      width: calc(100% - 10px);

      @include md {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    &__text {
      display: block;
    }

    &__image-warpper {
      img {
        max-width: unset;
      }
    }
  }
}
