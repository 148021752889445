.button {
  @include link-reset;

  width: 100%;
  max-width: $cta-width;
  padding: 14px 0;
  text-align: center;
  line-height: $font-size-s;
  letter-spacing: 0.1em;
  color: $orange;
  border: 1px solid $orange;
  font-family: $font-sans;
  font-weight: normal;
  text-transform: uppercase;
  font-size: $font-size-xs;
  transition: background-color 0.1s ease-in;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: $orange;
    color: $white;
    outline: 0;
  }

  &--narrow {
    width: auto;
    max-width: max-content;
    color: $white;
    background-color: $orange;
    padding: 14px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @include md {
      margin-left: 0;
      margin-right: 0;
    }

    &:hover,
    &:focus {
      background-color: $white;
      color: $orange;
      outline: 0;
    }
  }

  &--narrow-normal {
    width: auto;
    max-width: max-content;
    color: $white;
    background-color: $orange;
    padding: 14px;

    @include md {
      width: 100%;
      max-width: $cta-width;
      background-color: transparent;
      padding: 14px 0;
      color: $orange;
      border: 1px solid $orange;
      display: block;
    }

    &:hover,
    &:focus {
      background-color: transparent;
      color: $orange;
      outline: 0;

      @include md {
        background-color: $orange;
        color: $white;
        outline: 0;
      }
    }
  }
}
