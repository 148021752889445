$font-serif: "Sabon", "Times New Roman", Times, serif;
$font-sans: "NeueHaasGroteskText", Arial, Helvetica, sans-serif;

$font-size-xxl: 7.5rem;         //  120px
$font-size-xl: 2.813rem;         //  45px
$font-size-l: 2.375rem;          //  38px
$font-size-ml: 1.5rem;       //  20px
$font-size-m: 1.25rem;       //  20px
$font-size-base: 1rem;        //  16px
$font-size-s: 0.875rem;       //  14px
$font-size-xs: 0.75rem;       //  12px
$font-size-xxs: 0.625rem;        //  10px
