.logo-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 20px;
  border-top: 2px solid $grey-light-2;
  margin-bottom: 50px;

  &__item {
    display: flex;
    height: 50px;
    flex: 1 0 (100% / 5);
    width: auto;
    margin-bottom: 10px;
    justify-content: center;

    @include md {
      flex: 1 0 (100% / 5);
    }
  }

  &__item-image {
    width: auto;
    margin: auto;
    height: 100%;
  }
}
