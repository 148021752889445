.hero-block {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  /* stylelint-disable-next-line */
  aspect-ratio: 16/9;
  max-height: calc(100vh - 80px);

  &__image-wrapper {
    background-size: cover;
    background-position: top;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
  }

  &__video-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: $grey-light-2;
    }
  }

  &__headline {
    margin-bottom: 0;
  }

  &__content-wrapper {
    height: 100%;
    pointer-events: none;
    position: relative;

    @include sm {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    @include md {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__content-animation-wrapper {
    background-color: rgba(white, 0.8);
    padding: 40px 20px;

    @include sm {
      padding: 30px 50px;
    }

    &.active {
      @include md {
        transform: scale(1.25);
        animation: none;
        animation-fill-mode: both;
        animation-iteration-count: infinite;

        @-webkit-keyframes move-text {
          0% {
            transform: scale(1);
          }

          100% {
            transform: scale(1.25);
          }
        }
      }
    }
  }

  &__content {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 40px);

    @include xxl {
      margin-right: 20px;
    }

    @include md {
      transform: translate(0, 0);
      width: 500px;

      &--top-left {
        left: 0;
        right: auto;
        top: 100px;
        bottom: auto;

        @include md {
          margin-left: 70px;
        }
      }

      &--top-center {
        left: 50%;
        right: auto;
        top: 100px;
        bottom: auto;

        @include md {
          transform: translateX(-50%);
        }
      }

      &--top-right {
        left: auto;
        right: 0;
        top: 100px;
        bottom: auto;

        @include md {
          margin-right: 70px;
        }
      }

      &--center-left {
        left: 0;
        right: auto;
        top: 50%;
        bottom: auto;

        @include md {
          margin-left: 70px;
          transform: translateY(-50%);
        }
      }

      &--center-center {
        left: 50%;
        right: auto;
        top: 50%;
        bottom: auto;

        @include md {
          transform: translateX(-50%) translateY(-50%);
        }
      }

      &--center-right {
        left: auto;
        right: 0;
        top: 50%;
        bottom: auto;

        @include md {
          margin-right: 70px;
          transform: translateY(-50%);
        }
      }

      &--bottom-left {
        left: 0;
        right: auto;
        top: auto;
        bottom: 0;

        @include md {
          margin-left: 70px;
        }
      }

      &--bottom-center {
        left: 50%;
        right: auto;
        top: auto;
        bottom: 0;

        @include md {
          transform: translateX(-50%);
        }
      }

      &--bottom-right {
        left: auto;
        right: 0;
        top: auto;
        bottom: 0;

        @include md {
          margin-right: 70px;
        }
      }
    }

    &--no-background {
      @include md {
        width: 600px;
      }

      .hero-block {
        &__content-animation-wrapper {
          background-color: transparent;
        }
      }
    }

    &--no-width {
      width: calc(100vw - 40px);
    }
  }

  &__text {
    margin-top: 10px;

    @include md {
      margin-top: 30px;
    }

    p {
      line-height: 26px;
      text-align: center;
    }
  }

  &__scroll-arrow {
    display: none;

    @include sm {
      display: block;
      border-top: none;
      border-right: none;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      background-color: transparent;
      transform: rotate(-45deg);
      width: 18px;
      height: 18px;
      margin: auto auto 50px auto;
      pointer-events: visible;
    }
  }
}

.content--filter-active,
.content--subNav-active {
  .hero-block {
    @include md {
      max-height: calc(100vh - 130px);
    }
  }
}
