.slider-news {
  &__container {
    @include sm {
      display: flex;
      flex-wrap: nowrap;
      position: relative;
    }
  }

  &__content {
    height: auto;

    @include md {
      margin-bottom: 50px;
    }

    @include lg {
      margin-bottom: 70px;
    }
  }

  &__button {
    display: block;
    margin: 0 auto;
    width: 100%;
  }

  .swiper-wrapper {
    display: flex;
    flex-direction: column;

    @include sm {
      flex-direction: row;
    }
  }

  .swiper-container {
    @include sm {
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;

    @include sm {
      display: block;
      flex-shrink: 0;
    }

    &.swiper-button-disabled {
      visibility: hidden;
    }
  }

  .swiper-button-next {
    @include sm {
      transform: translateY(10vw);
      right: 0;
    }

    @include md {
      transform: translateY(11vw);
    }

    @include lg {
      transform: translateY(8vw);
    }

    @include xl {
      transform: translateY(105px);
    }
  }

  .swiper-button-prev {
    @include sm {
      left: 0;
      transform: translateY(10vw);
    }

    @include md {
      transform: translateY(11vw);
    }

    @include lg {
      transform: translateY(8vw);
    }

    @include xl {
      transform: translateY(105px);
    }
  }

  .swiper-slide {
    margin-bottom: $element-vertical-spacing-mobile;

    @include md {
      margin-bottom: 0;
    }

    &:not(:nth-child(-n+2)) {
      display: none;

      @include sm {
        display: block;
      }
    }
  }

  .content-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .tile {
    margin-left: 70px;
    margin-right: 70px;

    @include sm {
      margin-left: 0;
      margin-right: 0;
    }

    @include md {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;
    }

    &__text {
      height: 3em;

      @include sm {
        height: 5em;
        font-size: $font-size-base;
      }
    }
  }

  .teaser {
    margin: 0;

    img {
      @include sm {
        max-height: 140px;
        object-fit: cover;
      }

      @include md {
        max-height: 190px;
      }
    }
  }
}
