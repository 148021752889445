.teaser {
  position: relative;
  text-align: center;
  transition: transform 1s;

  @include sm {
    margin: 0;
  }

  &--fadeIn {
    transform: translateY(20%);
  }

  &.sal-animate {
    transform: none;
  }

  &__image-wrapper {
    display: block;
    margin-bottom: 25px;

    @include md {
      margin-bottom: 25px;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__headline {
    font-weight: normal;

    @include sm {
      font-size: $font-size-base;
      line-height: $font-size-m;
    }

    @include md {
      font-size: $font-size-m;
      line-height: 33px;
    }
  }

  &__subline {
    margin-bottom: 5px;
  }

  &__link {
    @include link-reset;

    color: black;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .force-aspect-ratio {
    display: block;
    position: relative;
    padding-bottom: 59.375%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}
