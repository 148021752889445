.banner {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @include md {
      width: 450px;
    }
  }

  &__headline {
    margin-bottom: 30px;
  }

  &__text {
    text-align: center;
  }

  &__button {
    margin-top: 25px;
  }

  &--black {
    .banner {
      &__text {
        color: white;
      }

      &__headline {
        color: white;
      }
    }
  }
}
