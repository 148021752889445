.language-select {
  list-style: none;
  font-family: $font-sans;
  text-transform: uppercase;
  font-size: $font-size-xs;
  position: relative;
  display: flex;
  align-items: center;

  @include md {
    background: url("../../images/svgs/arrow-downwards.svg") no-repeat center right;
    background-size: 9px;
    padding-right: 10px;
    margin-right: 25px;
    font-weight: normal;
    background-color: $white;

    &:hover,
    &:focus-within {
      .language-select__dropdown {
        display: block;
        opacity: 1;
        top: 100%;
      }
    }
  }

  &__current {
    display: none;

    @include md {
      display: block;
      background-color: $white;
      z-index: 1;
      padding-right: 6px;
      color: $orange;
    }
  }

  &__dropdown {
    margin: 0;
    padding: 0;

    @include md {
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      list-style: none;
      transition: opacity 200ms linear, top 200ms linear;
    }
  }
}
