html {
  scroll-behavior: smooth;
  scroll-padding-top: 120px;

  @include md {
    scroll-padding-top: 260px;
  }
}

body {
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $font-serif;
  color: $black;
  background-color: $off-white;
  margin: 0;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
