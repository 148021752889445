.navigation {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100vh - 80px);
  display: none;
  background-color: $off-white;
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: 101;

  @include lg {
    display: flex;
    position: unset;
    height: 50px;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 0;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    background-color: transparent;
  }

  &.active {
    display: flex;
  }

  &__container {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: scroll;

    @include lg {
      flex-direction: row;
      justify-content: center;
      overflow-y: unset;
    }
  }

  &__item {
    @include lg {
      display: flex;
      align-items: center;
    }

    &:not(:last-child) {
      margin-bottom: 30px;

      @include lg {
        margin-bottom: 0;
        margin-right: 15px;
        margin-left: 15px;
      }

      @include xl {
        margin-right: 20px;
        margin-left: 20px;
      }
    }

    &--only-mobile {
      @include lg {
        display: none;
      }
    }
  }

  &-sub {
    background-color: $off-white;
    margin-top: 30px;

    @include lg {
      border-top: solid $grey-light-2 1px;
      height: 50px;
      display: none;
      position: absolute;
      top: 80px;
      left: 0;
      margin-top: 0;
      width: 100vw;
    }

    &.active {
      display: block;
    }

    &__container {
      display: flex;
      flex-direction: column;

      @include lg {
        justify-content: center;
        flex-direction: row;
        height: 100%;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      white-space: nowrap;

      @include lg {
        margin-bottom: 0;
        margin-right: 20px;
        margin-left: 20px;
      }

      &:not(:last-child) {
        margin-bottom: 30px;

        @include lg {
          margin-bottom: 0;
        }
      }

      &--only-mobile {
        @include lg {
          display: none;
        }
      }
    }
  }
}
