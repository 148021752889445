.secondary-navigation {
  display: flex;
  flex-direction: column;
  padding-top: 25px;

  @include md {
    flex-direction: row;
  }

  & > *:not(:first-child) {
    margin-top: 25px;

    @include md {
      margin-top: 0;
    }
  }
}
