.teaser-gallery {
  margin: 50px auto;

  @include sm {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 50px;
    grid-auto-flow: row;
  }

  @include md {
    column-gap: 100px;
  }

  @include xl {
    column-gap: 155px;
    padding: 0;
  }

  & > * + * {
    padding-top: 50px;

    @include md {
      padding-top: 100px;
    }
  }

  &__disturber {
    padding: 40px 0;
    grid-column: 1 / 3;

    @include md {
      padding: 134px 0;
    }
  }

  .teaser--wide {
    grid-column: 1 / 3;

    .teaser__headline {
      @include sm {
        max-width: 445px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  .teaser--high {
    grid-row: span 2;

    // Sicherstellen, dass der untere der beiden Teaser rechts von diesem
    // nicht zu viel Platz beansprucht und die Ausrichtung weiterhin stimmt
    @include sm {
      & + .teaser--big + .teaser--big {
        margin-top: 0;
        align-self: end;
      }
    }
  }
}
