.article {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: $mint-light;

  &__button {
    margin: 0;
  }

  &__image-wrapper {
    position: relative;
    margin-top: 30px;
    margin-bottom: 30px;

    @include sm {
      margin-top: 50px;
      margin-bottom: 70px;
    }

    @include md {
      margin-bottom: 117px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__content {
    @include md {
      column-count: 2;
      column-gap: 55px;
    }

    @include lg {
      display: grid;
      grid-template-rows: 1fr auto;
      grid-column-gap: 95px;
      grid-template-columns: 1fr 2fr;
      column-count: unset;
    }
  }

  &__headline-wrapper {
    @include sm {
      grid-column: 1;
      align-self: start;
    }
  }

  &__overline {
    text-align: left;
    margin-bottom: 5px;

    @include sm {
      margin-bottom: 30px;
    }
  }

  &__headline {
    text-align: left;
    margin-bottom: 15px;
    max-width: 80%;

    @include sm {
      grid-column: 1;
    }

    @include md {
      grid-column: 1/2;
      margin-bottom: 80px;
      max-width: none;
    }

    @include lg {
      margin-bottom: 0;
    }
  }

  &__text {
    @include sm {
      grid-column: 2/3;
      grid-row: 1/4;
    }

    @include lg {
      column-count: 2;
      column-gap: 55px;
    }

    p {
      margin: 0;
    }
  }

  &__icon-wrapper {
    margin-top: 30px;

    @include lg {
      grid-column: 1/2;
      grid-row: 3;
      align-self: end;
      display: flex;
      flex-direction: column;
    }

    & > *:not(:first-child) {
      margin-left: 20px;

      @include lg {
        margin-left: 0;
        margin-top: 25px;
      }
    }
  }
}
