.hamburger {
  width: 25px;
  height: 19px;
  position: absolute;
  right: 20px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  justify-self: flex-end;

  @include lg {
    display: none;
  }

  &__stroke {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $black;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: 0;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 8px;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 16px;
      transform-origin: left center;
    }
  }

  &.open {
    .hamburger__stroke {
      &:nth-child(1) {
        transform: rotate(43deg);
        left: 0;
        top: -1px;
      }

      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-43deg);
        left: 0;
        top: 16px;
      }
    }
  }

  &:focus {
    outline: none;
  }
}
