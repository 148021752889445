.arrow-button {
  @include link-reset;

  display: inline-block;
  text-transform: uppercase;
  line-height: $font-size-s;
  color: $black;
  height: 1em;
  font-family: $font-sans;
  font-weight: normal;
  position: relative;
  transition: $transition-button;

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    transition: $transition-button;
    background: url("../../images/svgs/arrow-forward-edge.svg") no-repeat 50% 50%;
    background-size: contain;
    height: 14px;
    width: 14px;
    left: 55%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include sm {
      height: 23px;
      width: 23px;
    }
  }

  &:hover {
    background-color: $orange;

    &::after {
      transform: translate(-35%, -50%);
      background: url("../../images/svgs/arrow-forward-edge-white.svg") no-repeat 50% 50%;
    }
  }

  &--circle {
    border-radius: 50%;
    border: 1px solid $orange;
    width: 30px;
    height: 30px;

    @include sm {
      width: 51px;
      height: 51px;
    }
  }

  &--forward {
    transform: none;
  }

  &--backwards {
    &::after {
      transform: rotate(180deg) translate(64%, 50%);
    }

    &:hover::after {
      transform: rotate(180deg) translate(85%, 50%);
    }
  }

  &--back-text {
    font-size: $font-size-xs;

    @include md {
      font-size: $font-size-base;
    }

    &::before {
      content: "";
      display: inline-block;
      position: relative;
      width: 90px;
      height: 100%;
      transition: $transition-button;
      background: url("../../images/svgs/arrow-sidewards.svg") no-repeat 50% 50%;
      background-size: contain;
      margin-right: 5px;

      @include md {
        width: 117px;
        margin-right: 16px;
      }
    }

    &::after {
      display: none;
    }

    &:hover {
      background-color: transparent;
    }

    &:focus::before,
    &:hover::before {
      transform: translateX(-10px);
    }
  }

  &.disabled {
    pointer-events: none;
  }

  &--up {
    transition: opacity 1s ease-in-out;

    &::after {
      background: url("../../images/svgs/arrow-upwards.svg") no-repeat 50% 50%; /* stylelint-disable-line declaration-no-important */
      height: 19px;
      left: 50%;

      @include sm {
        left: 50%;
        height: 29px;
      }
    }

    &:hover::after {
      background: url("../../images/svgs/arrow-upwards-white.svg") no-repeat 50% 50%; /* stylelint-disable-line declaration-no-important */
      transform: translate(-50%, -65%);
    }
  }

  &:focus {
    outline: 0;
  }
}
