.tile-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 250px;
  width: 100%;

  &__overline {
    margin: 0;
    text-align: center;
  }

  .text {
    &--download {
      text-align: center;
      height: 100%;

      @include sm {
        min-height: 55px;
      }
    }
  }

  & > *:not(:first-child) {
    margin-top: 30px;
  }
}
