.scroll-top {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;

  &__button {
    pointer-events: all;
    position: absolute;
    bottom: 50px;
  }
}
