.headline {
  text-align: center;
  font-family: $font-sans;
  margin: 0 0 30px 0;

  @include md {
    margin: 0 0 50px 0;
  }

  &--level-1 {
    font-weight: bold;
    font-size: $font-size-m;
    letter-spacing: 1px;
    line-height: 29px;

    @include sm {
      font-size: $font-size-l;
      line-height: $font-size-xl;
    }
  }

  &--level-2 {
    font-weight: bold;
    font-size: $font-size-ml;
    text-transform: uppercase;
  }

  &--level-3 {
    font-weight: normal;
    text-transform: uppercase;
    font-size: $font-size-xs;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }

  &--no-space {
    margin: 0;
  }
}
