.milestone-wrapper {
  display: grid;
  grid-template-columns: 1fr;

  @include lg {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 75px;

    & > *:nth-child(odd) {
      grid-column: 2;
    }

    & > *:nth-child(even) {
      grid-column: 1;
      margin-top: 0;
    }
  }
}

.milestone {
  justify-self: center;
  width: 100%;
  margin-top: 30px;

  @include lg {
    margin-top: 0;
  }

  &:nth-child(odd):not(:first-child) {
    @include lg {
      margin-top: 134px;
    }
  }

  &__number {
    display: inline-block;
    font-size: 28px;
    font-weight: bold;

    @include md {
      font-size: 32px;
    }
  }

  &__text {
    p {
      display: inline;
    }
  }

  &__outline {
    text-align: center;
    white-space: nowrap;
    margin-left: -10px;

    @include sm {
      margin-left: -30px;
    }

    @include md {
      margin-left: -62px;
    }

    @include lg {
      margin-left: -20px;
    }

    @include xl {
      margin-left: -50px;
    }
  }

  &__content {
    text-align: center;
    margin: -27px auto 0;
    padding-left: 30px;
    padding-right: 30px;

    @include sm {
      padding-left: 0;
      padding-right: 0;
    }

    @include md {
      margin-top: -30px;
      width: 100%;
    }

    @include lg {
      width: 90%;
    }

    @include xl {
      margin-top: -35px;
      width: 100%;
    }

    & > * + * {
      margin-top: 20px;
    }

    &--no-image {
      margin-top: 15px;

      @include md {
        margin-top: 30px;
      }

      @include xl {
        margin-top: 30px;
      }
    }
  }
}
