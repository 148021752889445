.engagement-section {
  &__container {
    display: grid;
    grid-template-areas:
      "left"
      "right";

    @include md {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas: "right left";
    }
  }

  &__headline {
    margin: 0 auto $element-vertical-spacing-mobile;

    @include md {
      margin: 0 auto 50px;
    }
  }

  &__text {
    text-align: center;
    margin-bottom: 20px;
  }

  &__content {
    flex: 1;
    margin: 0 auto 30px auto;
    align-self: center;
    padding: 0;
    width: calc(100% - 60px);
    grid-area: left;

    @include md {
      padding: 30px 0;
    }

    @include md {
      margin-bottom: 0;
    }

    @include lg {
      padding: 20px 50px;
    }
  }

  &__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    grid-area: right;
  }

  &__image {
    max-width: 100%;
    width: 100%;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;

      @include md {
        object-fit: cover;
      }

      @include lg {
        object-fit: contain;
      }
    }
  }

  &--reverse {
    .engagement-section {
      &__image-wrapper {
        @include md {
          grid-area: left;
        }
      }

      &__content {
        @include md {
          grid-area: right;
        }
      }
    }
  }
}
