.section {
  padding: 40px 0;

  @include md {
    padding: 67px 0;
  }

  &--no-padding-bottom {
    padding-bottom: 0;
  }

  &--no-padding {
    padding: 0;
  }

  &--black {
    background-color: $soft-black;
  }

  &--grey {
    background-color: $grey-light;
  }

  &--headline {
    padding-bottom: 0;
    margin-bottom: -20px;
  }
}
