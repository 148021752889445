.simple-page {
  padding-top: 65px;

  h2 {
    font-family: $font-sans;
    font-size: $font-size-xs;
    font-weight: bold;
    text-transform: uppercase;
    margin: 50px 0 50px;

    @include md {
      margin: 100px 0 50px;
    }
  }

  h3 {
    font-family: $font-sans;
    font-size: $font-size-xs;
    font-weight: normal;
    text-transform: uppercase;
    margin: 50px 0 25px;
  }

  h4 {
    font-family: $font-serif;
    font-size: $font-size-xs;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    line-height: 1.6rem;
  }

  strong {
    font-weight: bold;
  }

  p {
    margin: 0 0 35px;
    line-height: 1.6rem;
  }

  a {
    color: $orange;
    text-decoration: none;

    &[href^="tel:"] {
      color: $black;
    }
  }

  & > *:last-child {
    margin-bottom: $flow-mobile;

    @include sm {
      margin-bottom: 100px;
    }
  }
}
