$flow: 134px;
$flow-mobile: 80px;

$content-width: 1095px;
$content-width-wide: 1230px;
$content-width-slim: 655px;
$content-padding: 20px;
$content-text-padding: 37px;

$min-height-header: 152px;

$content-padding-mobile: 62px;
$element-vertical-spacing-mobile: 30px;

$cta-width: 251px;

$hero-text-width: 469px;

//transiton
$transition-button: all 0.1s ease-in;
$transition-navigation: width 0.6s linear;
