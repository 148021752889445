@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=cfb55017-0e42-4ae7-92c9-91de82b41e9f&fontids=825376,825382,825388,1190925,1190934,1190938");

@font-face {
  font-family: "NeueHaasGroteskText";
  src:
    url("../../fonts/825376/a14594bf-73de-4b5f-9792-9566994a021d.woff2") format("woff2"),
    url("../../fonts/825376/bb4a10bb-155d-4c1a-a813-c65e10fac36c.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NeueHaasGroteskText";
  src:
    url("../../fonts/825382/34ae0cd2-c49c-4df4-8270-fcda21c1b715.woff2") format("woff2"),
    url("../../fonts/825382/9e666926-4bc9-4013-849e-dffa25a41dbd.woff") format("woff");
  font-display: swap;
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NeueHaasGroteskText";
  src:
    url("../../fonts/825388/d13fb250-6b64-4d97-85df-51fc6625a891.woff2") format("woff2"),
    url("../../fonts/825388/60fa2ce6-c35e-4203-9bbf-25dd128daec5.woff") format("woff");
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Sabon";
  src:
    url("../../fonts/1190925/a1fda929-9320-441c-ae8b-39449e110409.woff2") format("woff2"),
    url("../../fonts/1190925/0afd1c3e-9dd3-42e1-8db5-57bb97c3b4c1.woff") format("woff");
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Sabon";
  src:
    url("../../fonts/1190934/ec595c98-49a2-4f80-bbc5-01a8ad28b526.woff2") format("woff2"),
    url("../../fonts/1190934/89d9019b-be0f-4f4a-ac69-a68ea4da7c55.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Sabon";
  src:
    url("../../fonts/1190938/ef12a52c-a47f-49fe-b5bb-ee15aa7b5377.woff2") format("woff2"),
    url("../../fonts/1190938/13b0a4ee-3238-4837-bb6e-162ae2990401.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
