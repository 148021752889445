.contact-banner {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &__element {
    padding: 20px 0;
    width: 100%;

    @include md {
      width: calc(100% / 3);
    }
  }

  &__headline {
    margin-bottom: 20px;
  }

  &__link {
    display: block;
    text-align: center;
  }
}
